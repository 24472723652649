<template>

  <AppIntro/>
  <AppGames/>
  <AppSubscribe/>

</template>

<script>
import AppIntro from "@/components/intro";
import AppGames from "@/components/games";
import AppSubscribe from "@/components/subscribe";

export default {
  name: "AppHome",
  components: {
    AppIntro,
    AppGames,
    AppSubscribe,
  }
}
</script>

<style scoped>

</style>
