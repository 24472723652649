<template>
  <div class="artboard">

    <div class="title">
      <img src="../../src/assets/games/td/title.png" alt="">
    </div>
    <div class="buttons">
      <a class="link-appstore" v-bind:href="'https://apps.apple.com/in/app/id6443726094'" target="_blank"></a>
      <a class="link-google" v-bind:href="'https://play.google.com/store/apps/details?id=com.fliprix.td'"
         target="_blank"></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "TDComponent"
}
</script>

<style scoped lang="less">
.artboard {
  position: relative;
  background: url("~/src/assets/games/td/artboard.jpg") no-repeat no-repeat;
  background-size: 100vw auto;
  height: 400px;
  margin: 40px 0;
}

.title {
  position: absolute;
  top: 20%;
  width: 100%;
  height: 100%;

  img {
    width: 80vw;
    height: auto;
    max-height: 50%;
    object-fit: contain;
  }
}

.buttons {
  position: absolute;
  display: flex;
  bottom: 0;
  width: 100%;
  height: 150px;
  justify-content: center;
  align-items: center;
}

.buttons > a {
  margin: 0 20px;
}
</style>
