<template>
  <section>
    <div class="stories-wrapper">

      <div class="stories">
        <div class="story" v-for="game of games" v-bind:key="game.folder" @click="navigateToGame(game)">
          <img v-bind:src="require(`../assets/games/${game.folder}/icon.png`)">
        </div>
      </div>
    </div>

    <agile  class="mobile-only top-banner" :autoplay=true :navButtons="false" :dots="false" :autoplaySpeed="5000" :speed="2500"
           :fade=true>
      <div class="slide">
        <img alt="Vue logo" src="../assets/intro/intro_1.png">
        <p>Perfect your skill and make real money</p>
      </div>
      <div class="slide">
        <img alt="Vue logo" src="../assets/intro/intro_2.png">
        <p>Are you ready to win?</p>
      </div>
      <div class="slide">
        <img alt="Vue logo" src="../assets/intro/intro_3.png">
        <p>Head-to-head games with REAL prizes</p>
      </div>
      <div class="slide">
        <img alt="Vue logo" src="../assets/intro/intro_4.png">
        <p>Competitive gaming with cash rewards and real prizes</p>
      </div>
    </agile>
  </section>
</template>

<script>
import {GAMES} from "@/data";

import {VueAgile} from 'vue-agile'


export default {
  name: "AppIntro",
  components: {
    agile: VueAgile
  },
  data() {
    return {
      games: [...GAMES, ...GAMES],
    };
  },
  methods: {
    navigateToGame(game) {
      let element = document.getElementById(game.folder);
      element.scrollIntoView({behavior: "smooth", block: "end"});
    }
  }
}
</script>

<style scoped lang="less">

.top-banner {
  position: relative;

  img {
    border-radius: 16px;
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 288/420;
  }

  p {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    padding: 24px 16px;
    font-weight: bold;
    font-size: 8vw;
    line-height: 9vw;
  }

}

.stories-wrapper {
  position: relative;
  height: 20vw;
  max-height: 200px;
  overflow: hidden;
  margin: 32px -16px;
}

.stories {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  animation: scroll 30s linear infinite;

  .story {
    flex-shrink: 0;
    flex-grow: 0;
    width: 20vw;
    height: 20vw;
    max-height: 200px;
    max-width: 200px;

    border: 2px solid #2966ED;
    border-radius: 100%;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;

    }
  }

  .story + .story {
    margin-left: 5.5vw;
  }
}

@keyframes scroll {
  100% {
    left: -576px;
  }
}
</style>
