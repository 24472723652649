<template>
  <AppHeader></AppHeader>
  <router-view/>
  <AppHelp></AppHelp>
  <AppFooter></AppFooter>
</template>

<script>
import AppFooter from "./components/footer.vue";
import AppHeader from "./components/header";
import firebase from "firebase";
import AppHelp from "@/components/help";

export default {
  name: 'App',
  components: {
    AppHelp,
    AppFooter,
    AppHeader,
  },
  created: function () {

    const url = window.location.href;
    const urlWithoutFragment = url.split("#")[0];
    const items = urlWithoutFragment.split('from=');
    window['from'] = items.length > 1 ? items[1] : 'blank';

    // Your web app's Firebase configuration
    const firebaseConfig = {
      apiKey: "AIzaSyBguowEaBxVgSRbV5OZUs1hwJ4IwbOgQG4",
      authDomain: "fliprix.firebaseapp.com",
      databaseURL: "https://fliprix.firebaseio.com",
      projectId: "fliprix",
      storageBucket: "fliprix.appspot.com",
      messagingSenderId: "693678341177",
      appId: "1:693678341177:web:66c5e4192ee3a48656a1e8",
      measurementId: "G-826Y45VDYX"
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    document.analytics = firebase.analytics();
    document.analytics.logEvent("created", {from: window.from});

  },
  methods: {
    logEvent(eventName) {
      document.analytics.logEvent(eventName, {from: window.from});
    },
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
</style>

<style lang="less">
html, body {
  font-family: 'Montserrat', sans-serif;

  background: #1F2128;


  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
}

#app {
  margin: auto;
  background: #1F2128;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;

}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}

h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

button {
  cursor: pointer;
  background: #2966ED;
  border-radius: 6px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: white;
  padding: 14px 24px;
  height: 48px;
  border: 1px solid #2966ED;

  &.primary-white {

    background: #FFFFFF;
    color: #2966ED;
    border-color: white;
  }

  &.secondary {
    background: transparent;
    border-color: white;

  }
}

.align-center {
  text-align: center;
}

section {
  margin: 32px 16px;
}

.banner-wrapper {

  img {
    border-radius: 16px;
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 1/1;
  }
}

.desktop-only {
  @media only screen and (max-width: 700px) {
    display: none !important;;
  }
}

.mobile-only {
  @media only screen and (min-width: 700px) {
    display: none !important;;
  }
}

.link-appstore, .link-samsung, .link-google {
  display: block;
  background: url("./assets/Apple.png") no-repeat center;
  background-size: auto 40px;
  height: 40px;
  max-width: 147px;
  width: 100%;
}

.link-samsung {
  background-image: url("./assets/Galaxy.png");
}

.link-google {
  background-image: url("./assets/Google.png");
}

</style>

