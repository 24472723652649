<template>
  <section>
    <h2>GAMES</h2>
    <div class="games">
      <div class="game" v-for="game in games" v-bind:key="game.title" v-bind:id="game.folder">
        <div class="banner-wrapper" @click="learnMoreGame(game)">
          <img v-bind:src="require(`../assets/games/${game.folder}/preview.png`)">
        </div>
        <div class="info">
          <h3 :id="game.folder">{{ game.title }}</h3>
          <p>{{ game.description }}</p>


          <div class="buttons">
            <button class="mobile-only" @click="tryDownloadGame(game)">Download</button>
            <button class="mobile-only secondary" @click="learnMoreGame(game)">Learn more</button>

            <a class="desktop-only link-appstore" v-bind:href="game.iosUrl" target="_blank"></a>
            <a class="desktop-only link-samsung" v-if="game.galaxyUrl" v-bind:href="game.galaxyUrl" target="_blank"></a>
            <a class="desktop-only link-google" v-bind:href="game.androidUrl" target="_blank"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="download-popup-wrapper" v-if="download" @click="hideDownload()">
      <div class="download-popup">

        <h2 class="align-center">Download game</h2>

        <a class="link-appstore" v-bind:href="download.iosUrl" target="_blank"></a>
        <a class="link-samsung" v-if="download.galaxyUrl" v-bind:href="download.galaxyUrl" target="_blank"></a>
        <a class="link-google" v-bind:href="download.androidUrl" target="_blank"></a>
      </div>
    </div>
  </section>
</template>

<script>
import {GAMES} from "@/data";
import router from '@/router'

export default {
  name: "AppGames",
  data() {
    return {
      games: GAMES,
      download: null,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.hideDownload);
  },
  methods: {
    hideDownload() {
      this.download = null;
    },
    tryDownloadGame(game) {
      this.download = game;
    },
    learnMoreGame(game) {
      const gameIndex = this.games.indexOf(game);
      window.scrollTo(0, 0);
      router.push({name: `game`, params: {gameIndex}})
    }
  }
}
</script>

<style scoped lang="less">
section {
  max-width: 1000px;
  margin: auto;
}

h2 {
  padding-bottom: 32px;
  text-align: center;
}

.game {
  padding: 20px;
  @media screen and (min-width: 700px) {
    display: flex;

    .info {
      flex: 1 1 100%;
      padding: 0 32px;
    }

    .banner-wrapper {
      max-width: 40%;
    }
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 8px;
  }
}

.game + .game {
  margin-top: 48px;
}

.download-popup-wrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.3);
  z-index: 1000;
}

.download-popup {
  position: fixed;
  height: 280px;
  width: 100%;
  padding: 32px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background: #1F2128;
  border-radius: 16px 16px 0 0;

  z-index: 100;
  justify-content: space-between;
  align-items: center;

}

.buttons {

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 40px;

  * {
    //flex: 1;
    max-width: 40vw;
    margin-top: 16px;
  }

}


</style>
