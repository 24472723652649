export const GAMES = [
    {
        title: 'Knife Blitz',
        description: 'Kill monsters by throwing knives. fast-paced arcade game where players must throw knives at spinning targets without hitting the other knives already on the target. With challenging levels and different knife designs to unlock, it\'s a thrilling test of speed and accuracy.',
        iosUrl: 'https://apps.apple.com/app/apple-store/id1514431069',
        androidUrl: 'https://games.skillz.com/game/12089',
        skillzUrl: 'https://games.skillz.com/game/12089',
        galaxyUrl: 'https://galaxystore.samsung.com/detail/com.fliprix.knifeblitz',
        folder: 'knife',
        videoId: "CMBfG4_j1Zc",
        press: [{title: 'GamesKeys', url: 'https://gameskeys.net/ios-games-to-lookout-for-in-february-2021/'}],
        categories: ['Action', 'Arcade'],
        downloads: '45.2K Downloads',
        rules: 'hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm....  hm.... ',
        appleId: '1514431069',
    },
    {
        title: 'Dots Blitz',
        description: 'Fast-paced and addictive mobile game where players connect dots of the same color to earn points and win cash prizes. The game is easy to learn, but challenging to master. Dots Blitz is a fun and exciting way to test your puzzle-solving skills while having a chance to win real money.',
        iosUrl: 'https://apps.apple.com/app/dots-blitz-skillz-puzzle/id1503214833',
        androidUrl: 'https://games.skillz.com/game/11077',
        skillzUrl: 'https://games.skillz.com/game/11077',
        galaxyUrl: 'https://galaxy.store/dotsblitz',
        folder: 'dots',
        videoId: "VnGht6Ud8Ck",
        press: [{title: 'Skillz', url: 'https://www.skillz.com/skillz-indie-ignite-champions/'}],
        categories: ['Puzzle', 'Board'],
        downloads: '43.6K Downloads',
        rules: 'hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm....  hm.... ',
        appleId: '1503214833',
    },
    {
        title: 'Hexa Blitz',
        description: 'Puzzle game where players must fit variously shaped hexagonal blocks onto a grid. The goal is to clear as many lines as possible and achieve a high score before the grid becomes too crowded.',
        iosUrl: 'https://apps.apple.com/app/apple-store/id1503486117',
        androidUrl: 'https://games.skillz.com/game/11306',
        skillzUrl: 'https://games.skillz.com/game/11306',
        galaxyUrl: 'https://galaxy.store/hexaBlitz',
        folder: 'hexa',
        videoId: null,
        categories: ['Puzzle', 'Board'],
        downloads: '20.8K Downloads',
        rules: 'hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm....  hm.... ',
        appleId: '1503486117',
    },
    {
        title: 'Scorpion Solitaire',
        description: 'competitive version of the classic card game Scorpion Solitaire. Arrange all the cards in four columns, in a descending order from King to Ace while following suit.',
        iosUrl: 'https://apps.apple.com/app/id1527536271',
        androidUrl: 'https://games.skillz.com/game/13174',
        skillzUrl: 'https://games.skillz.com/game/13174',
        galaxyUrl: 'https://galaxystore.samsung.com/detail/com.fliprix.scorpionsolitaire',
        folder: 'solitaire',
        videoId: "bJDDPfawxFE",
        press: [{title: 'Skillz', url: 'https://www.skillz.com/skillz-developer-roundup-five-fresh-november-games/'}],
        categories: ['Cards', 'Solitaire'],
        downloads: '18.9K Downloads',
        rules: 'hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm....  hm.... ',
        appleId: '1527536271',
    },
    {
        title: 'Breakout',
        description: 'A modern twist of classic game in 3D with different power ups, levels and brick types. Arcade-style game where players must break through layers of bricks using a bouncing ball and paddle.',
        iosUrl: 'https://apps.apple.com/us/app/id1533423136',
        androidUrl: 'https://games.skillz.com/game/13173',
        skillzUrl: 'https://games.skillz.com/game/13173',
        galaxyUrl: 'https://galaxystore.samsung.com/detail/com.fliprix.arkanoid',
        folder: 'breakout',
        videoId: "7cw1xUMdFtI",
        categories: ['Action', 'Arcade'],
        downloads: '23.6K Downloads',
        rules: 'hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm....  hm.... ',
        appleId: '1533423136',
    },
    {
        title: 'Cross the Word',
        description: 'Word puzzle game where players must find all the words hidden in a grid of letters. The game offers difficulty levels and real cash rewards, making it a fun and challenging way to improve vocabulary and cognitive skills.',
        iosUrl: 'https://apps.apple.com/us/app/id1568311376',
        androidUrl: "https://games.skillz.com/game/15952",
        skillzUrl: "https://games.skillz.com/game/15952",
        galaxyUrl: "https://galaxystore.samsung.com/detail/com.fliprix.wordscapes",
        folder: 'wordscapes',
        videoId: null,
        categories: ['Word', 'Puzzle'],
        downloads: '15.8K Downloads',
        rules: 'hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm....  hm.... ',
        appleId: '1568311376',
    },
    {
        title: 'Sudoku',
        description: 'Enjoy our beautiful classic sudoku and improve your skills with learning system - win cash and real prizes. ',
        iosUrl: 'https://apps.apple.com/us/app/id1513054237',
        androidUrl: "https://games.skillz.com/game/15952",
        skillzUrl: "https://games.skillz.com/game/15952",
        galaxyUrl: "https://galaxystore.samsung.com/detail/com.fliprix.sudoku",
        folder: 'sudoku',
        videoId: null,
        categories: ['Board', 'Puzzle'],
        downloads: '7.8K Downloads',
        rules: 'hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm....  hm.... ',
        appleId: '1513054237',
    },
    {
        title: 'Neon Flip',
        description: 'Puzzle game where players must swap adjacent tiles to make a line of three or more matching tiles. Play your favourite match3 game, now in an eSports format and win real cash prizes.',
        iosUrl: 'https://apps.apple.com/us/app/id1578676805',
        androidUrl: "https://games.skillz.com/game/12075",
        skillzUrl: "https://games.skillz.com/game/12075",
        galaxyUrl: "https://galaxystore.samsung.com/detail/com.fliprix.match3",
        folder: 'match3',
        videoId: null,
        categories: ['Board', 'Puzzle'],
        downloads: '6.3K Downloads',
        rules: 'hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm....  hm.... ',
        appleId: '1578676805',
    },
    {
        title: 'Spider Solitaire',
        description: 'Multiplayer twist on the classic single-player card game where players aim to build stacks of cards in descending order. The game offers a challenging and engaging experience for solitaire fans.',
        iosUrl: 'https://apps.apple.com/us/app/1590527461',
        androidUrl: "https://games.skillz.com/game/21932",
        skillzUrl: "https://games.skillz.com/game/21932",
        galaxyUrl: "https://galaxystore.samsung.com/detail/com.fliprix.spidersolitaire",
        folder: 'spider',
        videoId: null,
        categories: ['Solitaire', 'Card'],
        downloads: '1.6K Downloads',
        rules: 'hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm....  hm.... ',
        appleId: '1590527461',
    },
    {
        title: 'Towers vs Monsters',
        description: 'Tower defense game where players must strategically place towers and use different types of weapons to defend against waves of monsters. With various levels and monster types, the game requires planning and quick reflexes to succeed.',
        iosUrl: 'https://apps.apple.com/in/app/id6443726094',
        androidUrl: "https://play.google.com/store/apps/details?id=com.fliprix.td",
        skillzUrl: null,
        galaxyUrl: null,
        folder: 'td',
        videoId: null,
        categories: ['Strategy', 'Action'],
        downloads: '',
        rules: 'hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm.... hm....  hm.... ',
        appleId: '6443726094',
    },
];
