<template>
  <section>
    <div class="subscribe-wrapper">

      <div class="subscribe">
        <h2>Subscribe for updates?</h2>
        <form class="subscribe-form" @submit.prevent="subscribe($refs.email.value)" ref="form">
          <input type="email" class="form-control" ref="email" placeholder="Enter your email" v-model="emailInput"
                 required
          >
          <button class="primary-white" type="button" @click="subscribe($refs.email.value)" :disabled="!isEmailValid">
            Subscribe
          </button>
          <div class="subscribed" v-bind:class="{ hidden: !isSubscribed }">
            <h3>{{ subscribingText }}</h3>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AppSubscribe",
  data() {
    return {
      isSubscribed: false,
      emailInput: '',
      subscribingText: '',
    }
  },
  computed: {
    isEmailValid() {
      return this.emailInput.length > 3 && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.emailInput);
    },
  },
  methods: {
    async subscribe(email) {
      this.isSubscribed = true;
      this.subscribingText = 'Subscribing...';
      // in a real app, it would be better if the URL is extracted as a env variable
      const sendMail = 'https://us-central1-fliprix.cloudfunctions.net/sendMail';
      const addSubscriber2 = 'https://us-central1-fliprix.cloudfunctions.net/addSubscriber2';
      const response = await fetch(addSubscriber2, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email: email})
      });
      const body = await response.json();
      console.log(JSON.stringify(body));
      if (body.status === 400) {
        await fetch(sendMail, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body)
        });
      }

      this.subscribingText = 'Thank you for subscribing!';
      setTimeout(() => {
        this.isSubscribed = false;
        this.emailInput = '';
        this.subscribingText = '';
      }, 5000);
    },
  }
}
</script>

<style scoped lang="less">

.subscribe-wrapper {
  margin: auto;
  max-width: 400px;
  padding: 40px 16px 0 16px;
  background: #2966ED;
  border-radius: 16px;
  position: relative;

  &:before {
    content: '';
    top: -8px;
    left: 8px;
    height: calc(100% + 16px);
    width: calc(100% - 16px);
    position: absolute;
    background: #2966ED;
    opacity: 0.4;
    border-radius: 16px;
    z-index: 1;
  }
}

.subscribe {
  position: relative;
  z-index: 10;
  background: url("../assets/cosmonaut.png") no-repeat center bottom;
  background-size: auto 162px;
  padding-bottom: 162px;

  h2 {
    margin-bottom: 20px;
  }
}

.subscribed {
  position: absolute;
}

.subscribe-form {
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
}

input {
  padding: 18px 12px;
  margin-bottom: 16px;
  width: 100%;
  height: 48px;

  background: #3E75EF;
  border: 1px solid #94B3F6;
  border-radius: 6px;

  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: white;

  &::placeholder {
    color: white;
  }
}

</style>
