<template>
  <section>
    <h2>Need Help?</h2>
    <div class="links">
      <a class="link" v-for="link in links" v-bind:key="link.title" v-bind:href="link.link" target="_blank">
        <p class="link-title">{{ link.title }}</p>
        <p class="link-description">{{ link.description }}</p>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: "AppHelp",
  data() {
    return {
      links: [
        {
          title: 'Search FAQ',
          description: 'Find answers to any question',
          link: 'https://skillz.zendesk.com/hc/en-us/categories/200051333',
        },
        {
          title: 'Contact Us',
          description: 'Did not find the answer to your question, write to us',
          link: 'mailto:support@fliprix.com?subject=Contact',
        },
        {
          title: 'Report Payment issue',
          description: 'Something went wrong with Payment, Deposite or Withdrawal',
          link: 'mailto:support@skillz.com',
        },
        {
          title: 'Report Game Issue',
          description: 'Something is broken in game mechanic',
          link: 'mailto:support@fliprix.com',
        },
      ]
    }
  }
}
</script>

<style scoped lang="less">
section {
  max-width: 1000px;
  margin: auto;
}

h2 {
  margin-bottom: 32px;
  text-align: center;
}

.link {
  text-decoration: none;
  display: block;
  padding: 16px 64px 16px 16px;
  border: 1px solid #FFFFFF;
  border-radius: 12px;
  margin-bottom: 16px;
  background: url("../assets/arrow.svg") no-repeat center right 12px;
}

.link-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  margin-bottom: 4px;
}

.link-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #FFFFFF;
}
</style>
