import {createRouter, createWebHistory} from 'vue-router'
import AppHome from '../components/home.vue'
import AppGame from '../components/game.vue'
import TDComponent from '../components/td.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: AppHome,
    },
    {
        path: '/game',
        name: 'game',
        component: AppGame,
    },
    {
        path: '/td',
        name: 'TDComponent',
        component: TDComponent,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
