<template>
  <section v-if="game">
    <div class="top-banner">
      <img v-bind:src="require(`../assets/games/${game.folder}/preview.png`)">
      <div class="banner-content">
        <div class="categories">
          <span>{{ game.categories[0] }}</span>
          <span>{{ game.categories[1] }}</span>
        </div>
        <h1>{{ game.title }}</h1>
        <p>{{ game.downloads }}</p>
      </div>
    </div>
    <p>{{ game.description }}</p>
    <div class="buttons">
      <a class="link-appstore" v-bind:href="game.iosUrl" target="_blank"></a>
      <a class="link-samsung" v-bind:href="game.galaxyUrl" target="_blank"></a>
      <a class="link-google" v-bind:href="game.skillzUrl" target="_blank"></a>
    </div>
    <!--    <div class="rules">-->
    <!--      <h2>Rules</h2>-->
    <!--      <p>{{ game.rules }}</p>-->
    <!--    </div>-->
    <div class="video-wrapper" v-if="game.videoId">
      <iframe width="100%" height="100%"
              v-bind:src="`https://www.youtube.com/embed/${game.videoId}?autoplay=1&mute=1&loop=1&playlist=${game.videoId}`"
              title="YouTube video player"
              frameborder="0"
              allow="autoplay"
              allowfullscreen></iframe>
    </div>
    <div class="screenshots">

    </div>
    <AppSubscribe></AppSubscribe>
    <AppMoreGames></AppMoreGames>
  </section>

</template>

<script>

import {GAMES} from "@/data";
import AppSubscribe from "./subscribe"
import AppMoreGames from "./moreGames"

export default {
  name: "AppGame",
  props: {},
  components: {
    AppSubscribe,
    AppMoreGames,
  },
  data() {
    return {game: null};
  },
  created() {

    this.game = GAMES[this.$route.params.gameIndex || 0];

    const appleEl = document.querySelector('head meta[name="apple-itunes-app"]');
    appleEl.setAttribute('content', 'app-id=' + this.game.appleId);


    this.$watch(
        () => this.$route.params,
        (toParams, previousParams) => {
          console.log(previousParams);
          console.log(toParams);

          this.game = GAMES[toParams.gameIndex];

          const appleEl = document.querySelector('head meta[name="apple-itunes-app"]');
          appleEl.setAttribute('content', 'app-id=' + this.game.appleId);
        }
    )
  },
}
</script>

<style scoped lang="less">
.categories {
  margin-bottom: 8px;
  text-align: left;

  span {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #FFFFFF;
    padding: 4px 12px;
    height: 23px;
    background: #83A7E4;
    border-radius: 4px;
  }

  span + span {
    margin-left: 8px;
  }
}

.top-banner {
  position: relative;
  margin-bottom: 32px;
  max-height: 80vh;
  overflow: hidden;

  &:after {
    content: "";
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    position: absolute;

    background: linear-gradient(180deg, rgba(41, 102, 237, 0) 30%, rgba(41, 102, 237, 0.9) 100%);
    border-radius: 16px;
  }

  img {
    border-radius: 16px;
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 288/420;
  }

  .banner-content {
    position: absolute;
    padding: 12px 12px 24px;
    bottom: 0;
    z-index: 10;

    p {
      margin-top: 8px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;

      &::before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        background: #FFFFFF;
        border-radius: 8px;
        margin-right: 8px;
      }
    }
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 24px 0;

  a {
    margin-top: 16px;
  }
}

.rules {
  h2 {
    margin-bottom: 12px;
  }

  p {
    margin-bottom: 32px
  }
}

.video-wrapper {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  overflow: hidden;
  height: 300px;
  margin-bottom: 64px;
}
</style>
