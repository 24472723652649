<template>
  <section>
    <h2>More Games</h2>
    <div class="games">
      <div class="games-content">
        <div class="game" v-for="game in games" v-bind:key="game.folder" @click="openGame(game)">
          <img v-bind:src="require(`../assets/games/${game.folder}/icon.png`)">
          <h3>{{ game.title }}</h3>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {GAMES} from "@/data";
import router from "@/router";

export default {
  name: "AppMoreGames",
  data() {
    return {
      games: GAMES,
    }
  },
  methods:{
    openGame(game){
      const gameIndex = this.games.indexOf(game);
      window.scrollTo(0, 0);
      router.push({name: `game`, params: {gameIndex}})
    }
  }
}
</script>

<style scoped lang="less">

.games {
  max-width: 100vw;
  overflow: scroll;
  padding: 32px 0;
  margin: 0 -32px;
}

h2 {
  background: url("../assets/scrollable_icon.svg") no-repeat right center;
}

.games-content {
  display: flex;
  padding: 0 32px;
  width: fit-content;
}

.game {
  width: 180px;
  height: 180px;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  flex-shrink: 0;
  flex-grow: 0;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(41, 102, 237, 0) 40%, rgba(41, 102, 237, 0.8) 100%);
  }

  h3 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 12px 16px;
    z-index: 10;
  }
}

.game + .game {
  margin-left: 16px;
}
</style>

