<template>
  <section>
    <!--    <nav>-->
    <router-link to="/">
      <img alt="Fliprix logo" src="../assets/Logo.png">
    </router-link>

    <!--      <div @click="isOpen=!isOpen" class="hamburger" :class="{ open: isOpen }">-->
    <!--        <span></span>-->
    <!--        <span></span>-->
    <!--        <span></span>-->
    <!--        <span></span>-->
    <!--        <span></span>-->
    <!--        <span></span>-->
    <!--      </div>-->
    <!--    </nav>-->
  </section>
</template>

<script>
export default {
  name: 'AppHeader',
  data() {
    return {
      isOpen: false,
    }
  },
}
</script>

<style scoped lang="less">

img {
  width: 100%;
  height: auto;
  object-fit: contain;
  max-width: 140px;
}

nav {
  display: flex;
  padding: 16px;
  background: #1F2128;
  border: 1px solid #2F3133;
  border-radius: 16px;
  justify-content: space-between;
  align-items: center;
}

.hamburger {

  width: 33px;
  height: 24px;
  position: relative;
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;


  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 50%;
    background: #2966ED;
    opacity: 1;
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  span:nth-child(even) {
    left: 50%;
    border-radius: 0 9px 9px 0;
  }

  span:nth-child(odd) {
    left: 0;
    border-radius: 9px 0 0 9px;
  }

  span:nth-child(1), span:nth-child(2) {
    top: 0;
  }

  span:nth-child(3), span:nth-child(4) {
    top: 10px;
  }

  span:nth-child(5), span:nth-child(6) {
    bottom: 0;
  }

  &.open span:nth-child(1), &.open span:nth-child(6) {
    transform: rotate(45deg);
  }

  &.open span:nth-child(2), &.open span:nth-child(5) {
    transform: rotate(-45deg);
  }

  &.open span:nth-child(1) {
    left: 3px;
    top: 5px;
  }

  &.open span:nth-child(2) {
    left: calc(50% - 3px);
    top: 5px;
  }

  &.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }

  &.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }

  &.open span:nth-child(5) {
    left: 3px;
    bottom: 5px;
  }

  &.open span:nth-child(6) {
    left: calc(50% - 3px);
    bottom: 5px;
  }


}

</style>
