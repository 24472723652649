<template>
  <section>
    <img alt="Vue logo" src="../assets/Logo.svg">
    <div class="social-links">

      <a class="youtube"
         href="https://www.youtube.com/channel/UCxRbqo90TFJJLymYqegySuQ" target="_blank" @click="logEvent('youtube')">
      </a>
      <a class="twitter"
         href="https://twitter.com/fliprix" target="_blank" @click="logEvent('twitter')">
      </a>
      <a class="facebook"
         href="https://www.facebook.com/fliprix.dev" target="_blank" @click="logEvent('facebook')">
      </a>
      <a class="instagram"
         href="https://www.instagram.com/fliprix" target="_blank" @click="logEvent('instagram')">
      </a>
    </div>
    <p class="copyright">Copyright © Fliprix OÜ 2023. All rights reserved.</p>
  </section>
</template>

<script>
export default {
  name: "AppFooter",
  props: {},
  methods: {
    logEvent(eventName) {
      document.analytics.logEvent(eventName, {from: window.from});
    },
  }
}
</script>

<style scoped lang="less">
.social-links {
  display: flex;
  justify-content: center;
  padding: 32px;

  a {
    display: block;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #2F3133;
    background: #1F2128 no-repeat center;

    &.youtube {
      background-image: url("../assets/youtube_icon.svg");
    }

    &.facebook {
      background-image: url("../assets/fb_icon.svg");
      background-size: auto 30px;
    }

    &.twitter {
      background-image: url("../assets/twitter_icon.svg");
    }

    &.instagram {
      background-image: url("../assets/instagram_icon.svg");
    }
  }

  a + a {
    margin-left: 16px;
  }

}

.copyright {
  text-align: center;
  opacity: 0.7;
  font-size: 12px;
}
</style>
